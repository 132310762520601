import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout/layout';
import SEO from '../components/SEO';
import { ROUTES } from '../config/constants';
import LatestBlogs from '../components/LatestBlogs';
import BlogSlider from '../components/BlogSlider';
import Achievements from '../components/Achievements';

const blog = ({ data }) => {
  // Extract data from the GraphQL query

  const latestBlogsData = data.latestBlogs.edges.map((edge) => edge.node);
  const blogSliderData = data.blogSlider.edges.map((edge) => edge.node);

  return (
    <Layout pageTitle="Latest Blog Posts">
      <SEO title={ROUTES.blog.title} description={ROUTES.blog.desc} />
      <LatestBlogs pageTitle="Latest Blog Posts" posts={latestBlogsData} />
      <BlogSlider posts={blogSliderData} />
      <Achievements
        title="Let’s take your digital experience to the next level"
        desc="Our team provides a wide range of services to help you maximize your business's potential. Whether you need web development services, enterprise resource planning, or UX design, we have the tools and experience to deliver your project under budget and ahead of schedule. Want to get started? Please fill out our form, and one of our engineers will get back to you within twenty-four hours."
        highlightBorder={false}
        showStamp={false}
        showProjectRequest={false}
        hideOnMobile={true}
        maxw="44rem"
      />
    </Layout>
  );
};

export default blog;

// Combined Root Query
export const query = graphql`
  query BlogsListQuery {
    latestBlogs: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog-post/.*.md$/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            cover
            keywords
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
    blogSlider: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog-post/.*.md$/" } }
      sort: { fields: frontmatter___date, order: DESC }
      skip: 4
      limit: 100
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            cover
            keywords
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`;
