import React from 'react';
import PropTypes from 'prop-types';

import {
  AchievementsWrapper,
  AchieveGrid,
  Card,
  CardTitle,
  ProjectRequest,
} from './style';
import Title from '../Title';
import Button from '../Buttons';
import { companyStats } from '../../config/constants';
import { StaticImage } from 'gatsby-plugin-image';

const CardComponent = (props) => {
  return (
    <Card>
      <CardTitle> {props.cardtitle} </CardTitle>
      <p>{props.carddesc}</p>
    </Card>
  );
};

function index({
  title,
  desc,
  highlightBorder,
  showStamp,
  showProjectRequest,
  hideOnMobile,
  maxw,
}) {
  return (
    <AchievementsWrapper
      showStamp={showStamp}
      showProjectRequest={showProjectRequest}
      hideOnMobile={hideOnMobile}
    >
      <StaticImage
        loading="lazy"
        alt="decorative stamp"
        imgClassName="strategy-stamp"
        className="strategy-stamp-wrapper"
        src="../../../static/img/strategist.png"
        objectFit="contain"
      />
      <div className="container">
        <Title
          title={title}
          desc={desc}
          align="center"
          maxw={maxw}
          highlightBorder={highlightBorder}
        />

        <AchieveGrid showProjectRequest={showProjectRequest}>
          {companyStats.map(({ cardtitle, carddesc, id }) => (
            <CardComponent key={id} cardtitle={cardtitle} carddesc={carddesc} />
          ))}
        </AchieveGrid>

        {showProjectRequest ? (
          <ProjectRequest>
            <form>
              <div className="form-group">
                <input id="userEmail" type="email" required />
                <label htmlFor="userEmail">Email ID</label>
              </div>
              <Button btnText="Start a Project" btnType="rounded-outline" />
            </form>
          </ProjectRequest>
        ) : (
          ''
        )}
      </div>
    </AchievementsWrapper>
  );
}

export default index;

CardComponent.propTypes = {
  cardtitle: PropTypes.string,
  carddesc: PropTypes.string,
};
