import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const BlogSlider = styled(Wrapper)``;

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background: linear-gradient(
    180deg,
    ${colors.spaceCadet},
    ${colors.spaceCadet2}
  );

  max-width: 21rem;
  margin: auto;

  @media ${device.laptop} {
    max-width: 100%;
    margin: 0 1rem;
  }

  @media ${device.laptopL} {
    margin: 0 1.2rem;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 500;

  & time {
    font-weight: 300;
    text-align: right;
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }

  & span {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    padding-right: 1rem;
  }
`;

export const CardImgBox = styled.div`
  display: flex;

  & img {
    height: auto;
    width: 100%;
    object-fit: cover;

    @media ${device.tablet} {
      height: 200px;
    }

    @media ${device.laptopL} {
      height: 300px;
    }
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;

  & button,
  a {
    margin-top: 2rem;
    text-transform: uppercase;
    color: ${colors.white};
    font-size: 1rem;
  }

  & h3 {
    font-size: 1.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media ${device.laptopL} {
      font-size: 1.8rem;
      margin: 0.8rem 0 1.2rem;
    }
  }

  & p {
    margin: 0rem;
    font-size: 1.1rem;
    color: ${colors.grey};
  }

  @media ${device.tablet} {
    p {
      margin: 0rem 0 3.5rem;
    }
  }

  @media ${device.laptop} {
    & button,
    a {
      margin-top: 0;
    }
  }
`;
