import React from 'react';
import { Button } from './style';
function index(props) {
  return (
    <Button
      type={props.type}
      as={props.as}
      to={props.to}
      href={props.href}
      btnType={props.btnType}
      displayBp={props.displayBp}
      minW={props.minW}
      target={props.target}
      rel={props.rel}
      {...props}
      hasIcon={props.icon && true}
    >
      {props.btnText}
      {props.icon && <span>{props.icon}</span>}
    </Button>
  );
}

index.defaultProps = {
  minW: '10rem',
};

export default index;
