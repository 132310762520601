import styled, { css } from 'styled-components';
import { device, size } from '../../layout/device';
import colors from '../../layout/globalColors';
import { Wrapper } from '../../layout/global-style';

export const AchievementsWrapper = styled(Wrapper)`
  position: relative;
  padding-top: 0;
  padding-bottom: 8rem;
  overflow: visible;
  background: url('/img/achieve-bg-line.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 9rem;

  & .strategy-stamp-wrapper {
    position: absolute;
    top: -8%;
    right: 0px;
    max-height: 236px;

    img {
      object-position: right;
    }

    @media ${device.tablet} {
      top: -24%;
    }
  }

  @media ${device.tablet} {
    & > img {
      max-height: 360px;
      top: -50%;
    }
  }

  @media ${device.laptop} {
    padding-top: 12rem;
    background-position: center 16rem;
    & > img {
      max-height: 280px;
      top: -25%;
    }
  }

  @media ${device.laptop} {
    & > img {
      top: -18%;
    }
  }

  /* control highlight border with props */
  ${(props) =>
    props.showStamp === false &&
    css`
      & > img {
        display: none;
      }
    `}

  /* control appearance in mobile with props */
  ${(props) =>
    props.hideOnMobile === true &&
    css`
      display: none;

      @media ${device.mobile} {
        display: block;
      }
    `}

  @media ${device.tablet} {
    background-position: center center;
    /* background position centered vertically if the project form is shown */
    ${(props) =>
      props.showProjectRequest &&
      css`
        background-position: center 18rem, center center;
      `}
  }

  @media ${device.desktop} {
    max-width: ${size.desktop};
    margin: auto;
    padding-top: 6rem;
    padding-bottom: 18rem;
  }
`;

export const AchieveGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.25rem;
  margin-top: 6rem;

  @media ${device.tablet} {
    margin-top: 8rem;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Card = styled.div.attrs(() => ({ tabIndex: 0 }))`
  background: linear-gradient(
    180deg,
    ${colors.spaceCadetDark},
    ${colors.spaceCadet2Dark}
  );
  border-radius: 1rem;
  padding: 2.5rem 1rem 1.25rem;
  max-width: 32rem;
  width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 10rem;

  @media ${device.mobile} {
    min-height: 12rem;
  }

  @media ${device.laptop} {
    &:not(:nth-of-type(2)) {
      margin-top: -4rem;
    }
  }

  @media ${device.laptopL} {
    min-height: 15rem;
  }

  @media ${device.desktop} {
    min-height: 18rem;
  }
`;

export const CardTitle = styled.h2`
  font-size: 3.8rem;
  line-height: 4.5rem;
  color: ${colors.white};
  margin-bottom: 0.5rem;

  @media ${device.tablet} {
    font-size: 4rem;
  }
`;

export const ProjectRequest = styled.div`
  margin: 10rem 0 0;
  display: flex;
  justify-content: center;

  & form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80vw;

    @media ${device.tablet} {
      flex-direction: row;
      flex-basis: 42rem;
    }
  }

  .form-group {
    display: flex;
    flex-grow: 2;
    flex-direction: column-reverse;
    margin-bottom: 2rem;
    position: relative;
    width: 100%;

    label {
      font-size: 0.9rem;
      position: relative;
      text-transform: uppercase;
      pointer-events: none;
    }
  }

  input {
    background: transparent;
    border-bottom: solid 2px ${colors.cadetBlue};
    font-size: 1.3rem;
    padding: 0.5rem 0;

    &:focus {
      outline: none;
      border-bottom: solid 2px ${colors.secondary};
    }

    & ~ label {
      bottom: -2rem;
      transition: all 0.2s ease-in;
    }

    &:focus ~ label,
    &:valid ~ label {
      bottom: -0.5rem;
      transition: all 0.2s ease-in;
    }
  }

  & button {
    margin-left: 1rem;
    display: flex;
    flex-shrink: 0;
  }
`;
