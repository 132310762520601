import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { CgChevronRightO } from '@react-icons/all-files/cg/CgChevronRightO';
import { v4 as uuidv4 } from 'uuid';
var dayjs = require('dayjs');

import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import Title from '../../components/Title';
import { BlogSlider, Card, CardInfo, CardImgBox, CardBody } from './style';
import { CtaLink } from '../Buttons/style';

const BlogCard = (props) => {
  return (
    <Card>
      <CardInfo>
        <span>{props.tag}</span>
        <time dateTime="2021-05-24T14:10:26-04:00">{props.postDate}</time>
      </CardInfo>
      <CardImgBox>
        <Link to={props.link}>
          <img src={props.img} alt={props.title} />
        </Link>
      </CardImgBox>
      <CardBody>
        <h3>{props.title}</h3>
        <p>{props.desc}</p>
        <CtaLink to={props.link} aria-label={props.title}>
          Read More
          <CgChevronRightO />
        </CtaLink>
      </CardBody>
    </Card>
  );
};

const index = ({
  title = 'All Posts',
  error = 'No articles at the moment',
  posts,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <BlogSlider>
      <div className="container">
        <Title title={title} align="center" />

        <Slider {...settings}>
          {!posts.length ? (
            <div>{error}</div>
          ) : (
            posts?.map((node) => {
              const { cover, date, keywords, title } = node.frontmatter;
              return (
                <BlogCard
                  key={uuidv4()}
                  img={cover}
                  title={title}
                  link={`/blogs${node.fields.slug}`}
                  desc={node.excerpt}
                  tag={keywords}
                  postDate={dayjs(date).format('DD MMM, YYYY')}
                />
              );
            })
          )}
        </Slider>
      </div>
    </BlogSlider>
  );
};

BlogCard.propTypes = {
  tag: PropTypes.string,
  postDate: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  link: PropTypes.string,
};

export default index;
