import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { CgChevronRightO } from '@react-icons/all-files/cg/CgChevronRightO';
import { v4 as uuidv4 } from 'uuid';

import Title from '../Title';
import { CtaLinkFillType } from '../Buttons/style';
import {
  LatestBlogWrapper,
  Card,
  CardInfo,
  CardImgBox,
  CardBody,
} from './style';

const BlogCard = (props) => {
  return (
    <Card>
      <CardImgBox>
        <Link to={props.link}>
          <img src={props.img} alt={props.title} width="440" height="380" />
        </Link>
      </CardImgBox>

      <CardBody>
        <CardInfo>
          <span>{props.tag}</span>
        </CardInfo>
        <h3>{props.title}</h3>
        <p>{props.desc}</p>

        <CtaLinkFillType to={props.link} color="#fff">
          READ MORE
          <CgChevronRightO />
        </CtaLinkFillType>
      </CardBody>
    </Card>
  );
};

const index = ({ pageTitle, posts }) => {
  return (
    <LatestBlogWrapper>
      <div className="container">
        <Title
          title={pageTitle}
          desc="Learn and grow your skills on our acclaimed IT blog. Get in-depth knowledge on software set-up, app development, and growing your business in the digital age."
          headingOf="page"
          align="center"
        />

        <div className="latest-blog-list">
          {!posts.length ? (
            <div>No Blogs found at the moment</div>
          ) : (
            posts?.map((node) => {
              const { cover, title, keywords } = node.frontmatter;
              return (
                <BlogCard
                  key={uuidv4()}
                  img={cover}
                  title={title}
                  link={`/blogs${node.fields.slug}`}
                  desc={node.excerpt}
                  tag={keywords}
                />
              );
            })
          )}
        </div>
      </div>
    </LatestBlogWrapper>
  );
};

BlogCard.propTypes = {
  tag: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  link: PropTypes.string,
};

export default index;
