import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const LatestBlogWrapper = styled(Wrapper)`
  .latest-blog-list {
    padding: 3rem 0 1rem;
  }

  @media ${device.tablet} {
    .latest-blog-list {
      padding: 6rem 0 1rem;
    }
  }
`;

export const Card = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.6rem;
  margin-bottom: 5rem;
  align-items: center;
  button {
    margin-top: 2rem;
    font-size: 1rem;
  }

  @media ${device.laptop} {
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2.8rem;

    button {
      margin-top: 3rem;
    }

    button svg {
      border-radius: 50%;
      background: ${colors.primary};
      font-size: 1.2rem;
    }
  }
`;

export const CardImgBox = styled.div`
  width: 100%;

  img {
    width: 100%;
    max-height: 224px;
    border-radius: 1rem;
    object-fit: cover;
  }

  @media ${device.tablet} {
    img {
      max-height: 100%;
      height: 380px;
    }
  }
`;
export const CardInfo = styled.div`
  margin-bottom: 0.8rem;
`;

export const CardBody = styled.div`
  padding: 1rem 0;
  & h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  & p {
    font-size: 0.9rem;
    color: ${colors.grey};
    margin-bottom: 2rem;
  }

  @media ${device.tablet} {
    padding: 1rem;
    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 0rem;
    }
  }

  @media ${device.laptop} {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 1rem;

    p {
      flex-grow: 2;
    }
  }
`;
